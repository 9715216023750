<template>
  <v-container fluid class="container-sequences">
    <v-layout justify-center>
      <v-flex lg10>
        <perseu-card :title="title">
          <template slot="content">
            <v-container grid-list-md>
              <v-layout>
                <!-- <v-flex lg2 class="mr-3">
                  <v-switch v-model="sequence.active" label="Ativo?" />
                </v-flex> -->
                <v-flex lg2>
                  <select-type
                    label="Tipo"
                    v-model="sequence.type"
                    :disabled="editMode"
                    @change="listPoints"
                    :filter-items-by-slug="availableSlugsForSequence"
                  />
                </v-flex>
                <v-flex lg4>
                  <v-text-field v-model="sequence.name" label="Nome" />
                </v-flex>
                <v-flex lg3>
                  <select-lang v-model="sequence.lang" label="Idioma" />
                </v-flex>
              </v-layout>
              <v-row>
                <v-col cols="12">
                  <div class="d-flex justify-end">
                    <v-btn color="error" @click="cancel" class="mr-2">
                      Cancelar
                    </v-btn>
                    <v-btn color="primary" @click="save">Salvar</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-layout class="mt-3" justify-space-between>
              <v-flex xs6>
                <v-layout justify-center>
                  <v-flex xs10>
                    <v-card>
                      <v-card-title class="subheading">
                        Pontos não atruibuídos
                      </v-card-title>
                      <v-card-text>
                        <!-- <filters-pares
                          @filter="filterNotAssigned"
                        ></filters-pares> -->
                        <!-- <v-divider></v-divider> -->
                        <v-list two-line>
                          <draggable
                            class="draggable unassigned-points"
                            group="points"
                            v-model="notAssignedPoints"
                            :clone="assignPoint"
                          >
                            <template
                              v-for="(item, index) in notAssignedPoints"
                            >
                              <point-card
                                :show-image="false"
                                :show-type="false"
                                :show-button="true"
                                icon-button="arrow_forward"
                                tooltip-button="Atribuir ponto a sequência"
                                class="mt-2"
                                :point="item"
                                :key="index"
                                order="Não atribuido"
                                @click="openDialogToAssign(item, index)"
                              />
                            </template>
                          </draggable>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs6>
                <v-layout justify-center>
                  <v-flex xs10>
                    <v-card>
                      <v-card-title class="subheading">
                        Pontos atribuídos
                      </v-card-title>
                      <v-card-text>
                        <v-list two-line>
                          <draggable
                            v-model="assignedPoints"
                            class="draggable assigned-points"
                            group="points"
                            :move="deletePointOrder"
                            :clone="unassignPoint"
                          >
                            <template v-for="(item, index) in assignedPoints">
                              <point-card
                                class="mt-2"
                                :show-image="false"
                                :show-type="false"
                                :show-button="true"
                                icon-button="arrow_back"
                                tooltip-button="Desatribuir ponto"
                                :point="item.trackingPoint"
                                :key="index"
                                :order="index"
                                @click="revokeAssigned(item, index)"
                              />
                            </template>
                          </draggable>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
        </perseu-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="assignFunction.dialog" max-width="600">
      <v-card>
        <v-card-title> Atribuir ponto </v-card-title>
        <v-card-text>
          <v-text-field
            label="Ordem"
            v-model.number="assignFunction.moveOrder"
            @keyup.enter="assignPointWithDialog"
          />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn @click="assignPointWithDialog" color="secondary">
            Atribuir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { save, findOne, deletePointOrder } from "@/services/sequences-service";
import { findTrackingPointsByType } from "@/services/pares-biomagneticos-service";
import { pagesNames } from "@/router/pages-names";
import {TypesSlugsEnum} from "@/domain/types_slugs.enum";
export default {
  components: {
    draggable,
    "point-card": () => import("@/components/Points/PointCardOrder"),
    "select-type": () => import("@/components/Types/Select"),
    /*  "filters-pares": () => import("./FiltersPares") */
  },
  data: () => ({
    sequence: {
      pointOrder: [],
      type: {},
    },
    assignedPoints: [],
    notAssignedPoints: [],
    assignFunction: {
      selectedPoint: {},
      selectedIndex: null,
      dialog: false,
      moveOrder: null,
    },
  }),
  computed: {
    title() {
      return (this.sequence.id ? "Editar" : "Nova") + " Sequência";
    },
    editMode() {
      return !!this.$route.query.id;
    },
    availableSlugsForSequence() {
      return [TypesSlugsEnum.RASTREIO, TypesSlugsEnum.RESERVATORIO,TypesSlugsEnum.TERAPIAS_COMPLEMENTARES]
    }
  },
  methods: {
    handleBeforeSave() {
      this.sequence.pointOrder = this.assignedPoints.map((po, index) => {
        delete po.trackingPoint.image;
        return {
          ...po,
          order: index,
        };
      });
    },
    async save() {
      try {
        this.$store.dispatch("loading/openDialog");
        this.handleBeforeSave();
        await save(this.sequence);
        this.$toasted.global.defaultSuccess();
        this.$router.push({ name: pagesNames.SEQUENCES_LIST });
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async findOne() {
      try {
        this.$store.dispatch("loading/openDialog");
        let { data } = await findOne(this.$route.query.id);
        this.sequence = data.sequence;
        this.notAssignedPoints = data.notAssignedPoints;
        this.assignedPoints = data.sequence.pointOrder;
      } catch (error) {
        this.$toasted.global.defaultError();
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async initialize() {
      if (this.$route.query.id) {
        await this.findOne();
      }
    },
    filterNotAssigned(query) {
      this.filteredPares = this.pares.filter((p) => {
        return new RegExp(query.description, "gi").test(p);
      });
    },
    async listPoints() {
      if (this.sequence.type) {
        const ids = this.sequence.pointOrder.map((po) => po.trackingPoint.id);
        const { data } = await findTrackingPointsByType(
          this.sequence.type.id,
          ids
        );
        this.notAssignedPoints = data;
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    assignPoint(value) {
      return {
        id: null,
        trackingPoint: value,
      };
    },
    deletePointOrder(value) {
      if (value.to.className === "draggable unassigned-points") {
        deletePointOrder(value.draggedContext.element);
      }
    },
    unassignPoint(value) {
      return value.trackingPoint;
    },
    openDialogToAssign(point, indexNotAssigned) {
      this.assignFunction.selectedIndex = indexNotAssigned;
      this.assignFunction.selectedPoint = point;
      this.assignFunction.dialog = true;
    },
    assignPointWithDialog() {
      if (this.assignFunction.moveOrder) {
        const pointOrder = {
          id: null,
          trackingPoint: this.assignFunction.selectedPoint,
        };
        const index = this.assignFunction.moveOrder - 1;

        this.assignedPoints.splice(index, 0, pointOrder);
        this.notAssignedPoints.splice(this.assignFunction.selectedIndex, 1);

        this.assignFunction.selectedPoint = {};
        this.assignFunction.moveOrder = null;
        this.assignFunction.dialog = false;

        this.$toasted.global.defaultSuccess();
      }
    },
    revokeAssigned(itemAssigned, indexAssigned) {
      deletePointOrder(itemAssigned);
      const unassignedPoint = itemAssigned.trackingPoint;
      this.assignedPoints.splice(indexAssigned, 1);
      this.notAssignedPoints.push(unassignedPoint);
      this.$toasted.global.defaultSuccess();
    },
  },
  created() {
    this.initialize();
  },
};
</script>

<style scoped>
.draggable {
  min-height: 50px;
}
.container-sequences {
  min-width: 768px;
}
</style>
